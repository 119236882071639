@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inconsolata" !important;
  font-size: medium !important;
  /* background-color: #391265 !important; */
  background-image: url("./assets/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: white !important;
}

p {
  font-size: 16px;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

a,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

footer {
  float: inline-end;
  font-weight: bold;
}

.slider .sliderRow {
  width: auto;
  height: 216px;
  /* background-size: auto; */
  animation: slide 60s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(1px, 0, 0);
  }
  100% {
    transform: translate3d(-1000px, 0, 0);
  }
}
.centeredItem {
  margin-left: auto;
  margin-right: auto;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.mainPad10 {
  padding-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.root {
  text-align: center;
}
.rootColor {
  text-align: center;
}

.rootColorBg {
  text-align: center;
  background-color: #5bef5b;
}

.closebtnleft {
  float: right;
}
.closebtnRight {
  float: left;
}
.footer {
  float: inline-end;
}

footer,
.footlink {
  width: -webkit-fill-available;
}

.faqBody {
  padding: 50px !important;
  color: black;
}
@media screen and (min-width: 992px) {
  .navOne {
    float: "left";
  }

  .navTwo {
    float: "right";
    margin-left: auto;
  }
}

@media screen and (max-width: 900px) {
  .mobile {
    display: flex;
  }
}
.rdmp1 {
  background-image: url("./assets/roadmap/roadmap1.png");
  height: 300px;
  width: 1000px;

  /* background-position: center; */
  background-size: contain;

  background-repeat: no-repeat;
  color: black;

  z-index: 2;
}

.main-1 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.roadmap-img {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}

.img-1 {
  width: 410px;
}

.rd-text1 {
  position: absolute;
  width: 50%;
  top: 50%;

  left: 50%;
  color: black;
  transform: translate(-50%, -50%);
}

h4 {
  color: black;
}

@media screen and (max-width: 500px) {
  .rd-text1 {
    width: 45%;
  }

  .img-1 {
    width: 350px;
  }
}

@media screen and (max-width: 380px) {
  .rd-text1 {
    width: 55%;
    font-size: 0.8rem;
  }

  .img-1 {
    width: 300px;
  }
}

.bdr {
  border-right: thick double #32a1ce;
}
/* @media screen and (max-width: 755px) {
  .rd-text1 {
    width: 45%;
  }

  .img-1 {
    width: 400px;
  }
} */

/* @media screen and (max-width: 420px) {
  .img-1 {
    width: 300px;
  }
  .rd-text1 {
    position: absolute;
    width: 58%;
    top: 50%;
    left: 50%;
    color: black;
    transform: translate(-50%, -50%);
  }
} */
